import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center" }
const _hoisted_2 = { class: "mx-4 max-w-4xl justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoriesPhotoList = _resolveComponent("CategoriesPhotoList")!
  const _component_PhotoListSkeleton = _resolveComponent("PhotoListSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", {
        style: {"margin-top":"10px"},
        class: "font-bold text-5xl flex-1 mb-4 justify-center"
      }, " Волонтерська діяльність церкви ", -1)),
      (_ctx.categories)
        ? (_openBlock(), _createBlock(_component_CategoriesPhotoList, {
            key: 0,
            class: "pr-4 justify-center sm:pr-2",
            categories: _ctx.categories
          }, null, 8, ["categories"]))
        : (!_ctx.categories && !_ctx.error)
          ? (_openBlock(), _createBlock(_component_PhotoListSkeleton, { key: 1 }))
          : _createCommentVNode("", true)
    ])
  ]))
}